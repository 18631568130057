export function getStatus(status){
  switch (status) {
    case 0:
      return '待发送';
    case 1:
      return '已发送';
    case 2:
      return '发送失败';
    case 3:
      return '取消发送';
  }
}

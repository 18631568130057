<template>
    <el-dialog
      :visible.sync="dialog"
      title="查看"
      append-to-body
      width="900px"
      class="emial-log-detail"
    >
      <el-form
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="描述">
          <div style="width: 370px;" class="similar-input">
            {{rowData.templateDesc}}
          </div>
        </el-form-item>
        <el-form-item
          label="发件人"
        >
          <div style="width: 370px;" class="similar-input">
            {{rowData.fromUser}}
          </div>
        </el-form-item>
        <el-form-item label="收件人" prop="recipientsEmail">
          <div class="similar-input">
            {{rowData.toUsers}}
          </div>
        </el-form-item>
        <el-form-item label="抄送邮箱">
          <div class="similar-input">
            {{rowData.ccUsers || '-'}}
          </div>
        </el-form-item>
        <el-form-item label="密送邮箱">
          <div class="similar-input">
            {{rowData.bccUsers}}
          </div>
        </el-form-item>
        <el-form-item label="邮件主题">
          <div class="similar-input content">
            {{rowData.title}}
          </div>
        </el-form-item>
        <el-form-item label="内容">
          <div class="similar-input content ql-container overflow-auto html-outer-box" v-html="rowData.content">
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
</template>

<script>
  export default {
    name: 'emailLookDetail',
    props: {
      rowData: {
        type: Object,
        default(){
          return {}
        }
      }
    },
    data() {
      return {
        dialog: false,
      }
    },
  }
</script>

<style lang="less" scoped>
  .emial-log-detail {
    .content {
      height: auto;
      min-height: 32px;
    }
  }
</style>

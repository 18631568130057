<template>
  <el-dialog
            :append-to-body="true"
            :visible.sync="dialog"
            title="失败记录"
            width="1000px"
  >
    <el-table :data="rowData.logList" size="small" stripe style="width: 100%;">
      <el-table-column prop="logId" label="编号"/>
      <el-table-column label="邮件主题">
        <template slot-scope="scope">
          <span>{{rowData.title}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="发送时间" />
      <el-table-column prop="failReason" label="失败原因" />
    </el-table>
  </el-dialog>
</template>

<script>
import { parseTime } from '@/utils/index'
export default {
  props: {
    rowData:{
      type: Object,
      default(){
        return {
          logList: [],
          updateTime: ''
        }
      }
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    parseTime,
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            @change="toQuery"
            v-model="query.status"
            clearable
            placeholder="状态"
            class="filter-item"
            style="width: 130px">
      <el-option v-for="item in queryTypeOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-input v-model="query.userInfo" clearable placeholder="收件人/ID" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.templateId" clearable placeholder="模板ID" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.mailGroupId" clearable placeholder="群发ID" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>

    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clearHandle">清空</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      queryTypeOptions: [
        { key: 0, display_name: '待发送' },
        { key: 1, display_name: '已发送' },
        { key: 2, display_name: '发送失败' },
        { key: 3, display_name: '取消发送' },
      ]
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    clearHandle(){
      this.$emit('clear');
    }
  }
}
</script>

import request from '@/utils/request'

export function resendEmail(data) {
  return request({
    url: `crm/resendEmail/${data.id}`,
    method: 'put',
    data
  })
}

export function updateStatus(data) {
  return request({
    url: `crm/sysSendMailQuartz	`,
    method: 'put',
    data
  })
}

export function queryMailDetail(data) {
  return request({
    url: `crm/queryMailDetail`,
    method: 'get',
    params: data,
  })
}

<template>
  <div class="app-container">
    <eHeader :query="query" @clear="clearHandel" @toQuery="toQuery" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="编号"/>
      <el-table-column prop="userId" label="收件人ID"/>
      <el-table-column prop="toUsers" label="收件人">
        <template slot-scope="scope">
          <div class="to-user">
            <div
              v-if="scope.row.userName"
              class="username">{{scope.row.userName}}</div>
            <div class="user-mail">{{scope.row.toUsers}}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="ccUsers" show-overflow-tooltip label="抄送人"/>
      <el-table-column prop="bccUsers" label="密送人"/>
      <el-table-column prop="templateId" label="模板ID">
        <template slot-scope="scope">
          <span>{{scope.row.templateId || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mailGroupId" label="群发ID"/>
      <el-table-column prop="title" min-width="180px" label="邮件主题" align="center"/>
      <!-- 需显示上级名称 -->
    <el-table-column prop="createTime" label="发送时间"/>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <div class="">{{getStatus(scope.row.status)}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="getLookFlag()"
            @click="lookHandle(scope.row)"
            size="mini"
            type="success"
          >查看</el-button>
          <el-button
            type="success"
            size="mini"
            class="margin-right5"
            v-if="getFailBtnFlag(scope.row)"
            @click="detailHandle(scope.row)"
          >失败记录</el-button>
          <el-dropdown
            size="mini"
            v-if="getFlag(scope.row)"
          >
            <el-button size="mini" type="success">
              更多操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                class="dropdown-item"
                v-if="getFlag(scope.row)"
              >
                <div
                  @click="cancleHandle(scope.row)"
                >取消发送</div>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="getFlag(scope.row)"
              >
                <div  @click="resendHandle(scope.row.id)">立刻重发</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <detail-dialog
      ref="detaildialog"
      :row-data="rowData"
    ></detail-dialog>
    <email-log-detail
      ref="emaillogdetail"
      :row-data="rowData"
    ></email-log-detail>
  </div>
</template>

<script>
  import emailLogDetail from '@/components/notificationManager/emailLog/emailLogDetail'
  import { mapGetters } from 'vuex'
import { resendEmail,updateStatus,queryMailDetail } from '@/api/notificationManager/emailLog'
import { getStatus } from '@/components/notificationManager/emailLog/utils'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/notificationManager/emailLog/header';
import detailDialog from '@/components/notificationManager/emailLog/detail';
export default {
  name:'emailLog',
  components: {
    eHeader,
    detailDialog,
    emailLogDetail,
  },
  mixins: [initData],
  data() {
    return {
      query:{
        userInfo: '',
        templateId: '',
        mailGroupId: '',
        status: '',
      },
      delLoading: false,
      rowData:{},
      btnLoading: false,
    }
  },
  created() {
    if(this.messageOrMailRecordId){
      this.query.mailGroupId = this.messageOrMailRecordId;
    }
    this.$nextTick(() => {
      this.init()
    });
  },
  beforeDestroy(){
    this.$store.commit('setMessageOrMailRecordId', '');
  },
  computed: {
    ...mapGetters(['messageOrMailRecordId']),
  },
  methods: {
    getStatus,
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'crm/sysSendMailQuartz'
      const sort = 'id,desc'
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
      }
      if (this.query.userInfo) {
        this.params.userInfo = this.query.userInfo;
      }
      if (this.query.templateId) {
        this.params.templateId = this.query.templateId;
      }
      if (this.query.status !== '') {
        this.params.status = this.query.status;
      }
      if (this.query.mailGroupId !== '') {
        this.params.mailGroupId = this.query.mailGroupId;
      }
      return true
    },
    clearHandel(){
      this.query = {
        userInfo: '',
        templateId: '',
        status: '',
      }
      this.toQuery();
    },
    detailHandle(row){
      this.rowData = row;
      this.$refs.detaildialog.dialog = true;      
    },
    resendHandle(id){
      this.btnLoading = true;
      resendEmail({id: id}).then((res) => {
        this.btnLoading = false;
        this.$message({
          message: '重发成功',
          type: 'success'
        });
      }).catch((error) => {
        this.btnLoading = false;
      })
    },
    getLookFlag(){
      return checkPermission(['ADMIN','SysSendMailQuartz_ALL','SysSendMailQuartz_EDIT']);
    },
    getFlag(row){
      return checkPermission(['ADMIN','SysSendMailQuartz_ALL','SysSendMailQuartz_EDIT']) && (row.status === 2);
    },
    cancleHandle(row){
      this.btnLoading = true;
      updateStatus({
        id: row.id,
        status: 3,
      }).then((res) => {
        this.btnLoading = false;
        this.$message({
          message: '操作成功'
        });
        this.init();
      }).catch((error) => {
        this.btnLoading = false;
      })
    },
    getFailBtnFlag(row){
      return checkPermission(['ADMIN','SysSendMailQuartz_ALL','SysSendMailQuartz_EDIT']) && row.logList.length;
    },
    lookHandle(row){ 
      this.rowData=row
      queryMailDetail({mailId: row.id}).then((res) => {
        this.rowData.content = res.content
        this.$refs.emaillogdetail.dialog = true;
      }).catch((error) => {
        this.$refs.emaillogdetail.dialog = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
